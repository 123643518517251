<template>
  <app-header :routes="routes" />
  <Main>
    <quote-form-wrapper v-if="!$_.isEmpty(shipmentQuote)">
      <sdCards headless>
        <FormValidationWrap>
          <VerticalFormStyleWrap>
            <a-form
              ref="formRef"
              name="sDash_validation-form"
              layout="vertical"
              :model="formData"
              :validateOnRuleChange="false"
            >
              <a-row :gutter="10">
                <a-col
                  v-for="(isVisible, category) in quoteCategoriesVisible"
                  :xs="24"
                  :key="category"
                >
                  <a-row v-if="isVisible">
                    <a-col
                      class="quote-table-forms"
                      :xs="20" :sm="22"
                    >
                      <category-items
                        :parentKey="category"
                        :categoryId="categoryId(category)"
                        :title="$t(`models.priceCategory.attributes.categoryTypes.${category}`)"
                        :items="linkedSubcategoryItems(category)"
                        :unlinkedItems="unlinkedSubCategoryItems(category)"
                        :destroyAll="!quoteCategoriesVisible[category]"
                        :template="true"
                        @updateSubForm="formData[category] = $event"
                      />
                    </a-col>
                    <a-col :xs="4" :md="2">
                      <sdButton
                        v-if="canRemove(category)"
                        outlined
                        class="btn-icon remove-item-btn"
                        type="default"
                        shape="circle"
                        :tooltip="$t('actions.remove')"
                        @click="removeCategory($event, category)"
                      >
                        <sdFeatherIcons type="minus-circle" stroke="#E83557" size="14" html-type="button" />
                      </sdButton>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <div class="sDash_form-action">
                <dropdown
                  v-if="categoryOptions.length > 0"
                  :title="$t('views.shipmentQuote.addCategory')"
                  :isButton="true"
                  :loading="loading"
                  :options="categoryOptions"
                  @selectedOption="addCategory"
                />
                <a-button
                  class="btn-signin mr-10 float-button-right"
                  size="small"
                  type="primary"
                  @click="submitForm()"
                >
                  <span>{{$t('actions.submit')}}</span>
                </a-button>
              </div>
            </a-form>
          </VerticalFormStyleWrap>
        </FormValidationWrap>
      </sdCards>
    </quote-form-wrapper>
    <sdCards v-else class="modifications-card">
      <a-row type="flex" justify="center" align="middle">
        <a-col :span="12" class="text-center" >
          <sd-heading as="h5">
            {{$t('views.shipmentRequest.addQuoteTemplate')}}
          </sd-heading>
        </a-col>
      </a-row>
    </sdCards>
  </Main>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {useStore} from 'vuex';
import _ from 'lodash';
import {Main} from '@/components/shared/styledComponents/Main';
import CategoryItems from '@/components/shipmentQuoteForm/CategoryItems';
import Dropdown from '@/components/dropdown/Dropdown';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import {QuoteFormWrapper} from './style';
import {scrollToFirstError} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'ShipmentQuoteForm',
  components: {
    Main,
    CategoryItems,
    Dropdown,
    VerticalFormStyleWrap,
    FormValidationWrap,
    QuoteFormWrapper
  },
  setup() {
    const {
      dispatch, getters
    } = useStore();

    const formData = reactive({});
    const quoteCategoriesVisible = reactive({});

    const shipmentQuote = computed(() => getters.shipmentQuote);

    const categoryOptions = computed(() => {
      const options = [];
      _.forEach(quoteCategoriesVisible, (visible, key) => {
        if (!visible) options.push({value: key, label: key});
      });
      return options;
    });

    const init = async () => {
      dispatch('toggleLoading', true);
      await dispatch('getQuoteTemplate', {params: {[_.snakeCase('detailedQuote')]: true}});

      Object.assign(quoteCategoriesVisible, {});
      const orderedQuoteCategories = _.sortBy(shipmentQuote.value.categories, ({name}) => name !== 'service');
      _.forEach(orderedQuoteCategories, (category) => quoteCategoriesVisible[category.name] = true);
      _.forEach(shipmentQuote.value.unlinkedSubCategories, (category) => {
        if (!getCategory(category.name, 'categories')) {
          quoteCategoriesVisible[category.name] = false;
        }
      });
      dispatch('toggleLoading', false);
    };

    const addCategory = (key) => quoteCategoriesVisible[key] = true;
    const canRemove = (category) => !_.includes(['duties', 'service', 'vat'], category);
    const removeCategory = (e, key) => {
      e.preventDefault();
      _.forEach(formData[key], (item) => item._destroy = 1);
      quoteCategoriesVisible[key] = false;
    };

    watch(formData, (data) => {
      _.forEach(data, (category, key) => {
        if (_.every(category, (item) => item._destroy === 1)) quoteCategoriesVisible[key] = false;
      });
    }, {deep: true, nested: true});

    const formRef = ref();
    const loading = ref(false);
    const submitForm = async () => {
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          const form = {[_.snakeCase('quoteSubCategoriesAttributes')]: []};
          _.forEach(formData, (value, key) => {
            form[_.snakeCase('quoteSubCategoriesAttributes')].push(...value);
          });
          await dispatch('updateShipmentQuote', {
            id: shipmentQuote.value._jv.id, form, updateType: 'submit'
          });
          loading.value = false;
        }).catch(({errorFields}) => {
          scrollToFirstError(formRef, errorFields);
        });
    };

    const routes = computed(() => {
      return [{
        breadcrumbName: 'layout.shipmentQuote.template'
      }];
    });

    const getCategory = (categoryName, key) => _.find(shipmentQuote.value[key], ({name}) => name === categoryName);
    const linkedSubcategoryItems = (categoryName) => getCategory(categoryName, 'categories')?.subCategories;
    const unlinkedSubCategoryItems = (categoryName) => getCategory(categoryName, 'unlinkedSubCategories')?.subCategories;
    const categoryId = (categoryName) => getCategory(categoryName, 'categories')?.id || getCategory(categoryName, 'unlinkedSubCategories')?.id;

    init();

    return {
      loading,
      formRef,
      formData,
      shipmentQuote,
      quoteCategoriesVisible,
      categoryOptions,
      canRemove,
      removeCategory,
      addCategory,
      submitForm,
      linkedSubcategoryItems,
      unlinkedSubCategoryItems,
      categoryId,
      routes
    };
  }
});
</script>

<style lang="scss" scoped>
.add-more-button-col {
  align-self: end;
  text-align: center;
}

.quote-table-forms > div:first-of-type {
  padding-bottom: 0px
}
</style>
